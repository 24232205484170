import React from 'react';

const DashIcon = () => (
  <svg
    className="mr-4"
    width="61"
    height="2"
    viewBox="0 0 61 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.279297"
      y="0.853516"
      width="60.3477"
      height="0.994574"
      rx="0.497287"
      fill="#2B91A6"
    />
  </svg>
);
export default DashIcon;
