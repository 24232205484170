/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable no-console */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

import emailjs from '@emailjs/browser';
import { toast, ToastContainer } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';
import DashIcon from '../icons/Dash';
import 'react-toastify/dist/ReactToastify.css';
import Faq from './Faq';

const ContactSection = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulContactPage {
        about
        heading
        bannerImage {
          title
          gatsbyImageData(placeholder: BLURRED)
        }
        contactSvgImage {
          gatsbyImageData(placeholder: BLURRED)
        }
        addresses {
          url
          name
          address
          icon {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        socials {
          url
          name
          imageIcon {
            title
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      },
      ,
      frame1: file (relativePath: { eq: "Contact us-amico.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED )
        }
      },
    }
  `);

  const bannerData = data.contentfulContactPage.bannerImage;
  const socialListData = data.contentfulContactPage.socials;
  const addressListData = data.contentfulContactPage.addresses;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [value, setValue] = useState(true);
  const [message, setMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRecaptchaSet, setRecaptcha] = useState(false);
  const [captchaErrorMessage, setCaptchaErrorMessage] = useState(false);

  const recaptchaRef = React.createRef();
  const sendEmail = (event, captcha) => {
    event.preventDefault();

    emailjs
      .sendForm(
        'service_ahrfw0k',
        'template_wqu518c',
        '.form-elements',
        'JMkVQSBO6D4QzGuiG',
      )
      .then(
        (response) => {
          if (response.status === 200) {
            toast.success(
              'Message send successful',
              { autoClose: 3000 },
            );
            setName('');
            setEmail('');
            setPhone('');
            setMessage('');
            setValue('');
            captcha.reset();
          }
          setIsSubmitting(false);
        },
        (response) => {
          if (response.status === 400) {
            toast.error(
              'Message failed, Please check the captcha form.',
              { autoClose: 2000 },
            );
            setName(name);
            setValue(true);
            setEmail(email);
            setPhone(phone);
            setMessage(message);
          }
        },
      );
    recaptchaRef.current.reset();
  };
  const handleRecaptchaClick = (recaptchaValue) => {
    if (recaptchaValue) {
      setRecaptcha(true);
      setCaptchaErrorMessage(false);
    } else {
      setCaptchaErrorMessage(true);
    }
  };

  const handleInvalid = () => {
    if (!isRecaptchaSet) {
      setCaptchaErrorMessage(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isRecaptchaSet) {
      setCaptchaErrorMessage(true);
      return;
    }
    const captcha = recaptchaRef.current;
    setIsSubmitting(true);
    sendEmail(event, captcha);
  };
  return (
    <section>
      <div className="relative md:py-0 py-24 flex content-center items-center md:min-h-[65vh] min:h-[75vh] mb-[-5.4375] ">
        <div className="absolute h-full flex top-0 w-full bg-center bg-cover bg-gradient-to-br from-black via-gray-900 to-transparent">
          <GatsbyImage style={{ height: 'auto' }} image={bannerData.gatsbyImageData} alt={bannerData.title} className="w-full h-auto object-cover" />
        </div>
      </div>
      <div className="lg:py-[80px] py-10 bg-background px-[1.125rem] md:px-[3.25rem]">
        <div className="container mx-auto">
          <div className="flex flex-wrap items-center lg:justify-between -mx-4 bg-none md:bg-outlined md:py-[80px] pt-[40px] pb-0 px-[20px] md:px-[40px]">
            <div className="w-full lg:w-[35%] xl:w-[35%] xl:order-none order-2">
              <div className="xl:mb-12 mb-0 lg:mb-0 bg-none xl:bg-white py-[40px]">
                <div className="md:mb-[4.5rem] lg:mb-[2.5rem] mb-0">
                  <div className="mx-auto w-[250px] hidden xl:block">
                    <GatsbyImage image={data.contentfulContactPage.contactSvgImage.gatsbyImageData} className="" alt="contact SVG" />
                  </div>
                </div>
                {addressListData.map(({
                  url, name, address, icon,
                }, index) => (
                  <div className="flex mb-8 mx-auto max-w-[370px] w-full items-center" key={index}>
                    <div
                      className="
                      max-w-[60px]
                      sm:max-w-[70px]
                      w-full
                      h-[60px]
                      sm:h-[70px]
                      flex
                      items-center
                      overflow-hidden
                      text-primary
                      rounded
                      "
                    >
                      <GatsbyImage image={icon.gatsbyImageData} className="" alt={name} />
                    </div>
                    <div className="w-full">
                      <h4 className="font-bold text-white xl:text-background text-xl mb-1">{name}</h4>
                      <a href={url} alt={name} className="text-base text-white xl:text-background">
                        {address}
                      </a>
                    </div>
                  </div>
                ))}
                <div className="mx-auto max-w-[370px] lg:flex mb-8 md:mt-[4.5rem] hidden">
                  {socialListData.map(({
                    url, name, imageIcon,
                  }, index) => (
                    <a href={url} alt={name} key={index} className="mx-3 text-gray-700 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400" aria-label={name}>
                      <GatsbyImage image={imageIcon.gatsbyImageData} className="" alt={name} />
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-full lg:w-[45%] xl:w-[45%] md:px-4 px-0 xl:order-none order-3">
              <div className="relative rounded-lg sm:p-12">
                <form onSubmit={handleSubmit} onInvalidSubmit={handleInvalid} className="form-elements">
                  <div>
                    <div className="mb-6">
                      <input
                        type="text"
                        id="name"
                        placeholder="Your Name"
                        className="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        bg-outlined
                        text-base
                        border-b border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                        onChange={(e) => setName(e.target.value)}
                        validate={{
                          required: {
                            value,
                            errorMessage: 'Please enter your name',
                          },
                        }}
                      />
                      <span className="error" data-validate-for="name" />
                    </div>
                    <div className="mb-6">
                      <input
                        type="email"
                        nmae="email"
                        placeholder="Your Email"
                        className="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        bg-outlined
                        text-base
                        border-b border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                        onChange={(e) => setEmail(e.target.value)}
                        validate={{
                          required: {
                            value,
                            errorMessage: 'Please enter your email',
                          },
                        }}
                      />
                      <span className="error" data-validate-for="email" />
                    </div>
                    <div className="mb-6">
                      <input
                        type="text"
                        id="phone"
                        placeholder="Your Phone"
                        className="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        bg-outlined
                        text-base
                        border-b border-[f0f0f0]
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <span className="error" data-validate-for="phone" />
                    </div>
                    <div className="mb-6">
                      <textarea
                        rows="6"
                        id="comment"
                        placeholder="Share your thoughts"
                        className="
                        w-full
                        rounded
                        py-3
                        px-[14px]
                        bg-outlined
                        text-base
                        border-b border-[f0f0f0]
                        resize-none
                        outline-none
                        focus-visible:shadow-none
                        focus:border-primary
                        "
                        onChange={(e) => setMessage(e.target.value)}
                        validate={{
                          required: {
                            value,
                            errorMessage: 'Please enter your message',
                          },
                        }}
                      />
                      <span className="error" data-validate-for="comment" />
                    </div>
                  </div>
                  <div style={{ marginBottom: '30px' }}>
                    <ReCAPTCHA
                      size="normal"
                      name="captcha"
                      id="recaptcha"
                      ref={recaptchaRef}
                      onChange={handleRecaptchaClick}
                      sitekey="6LeQPZEgAAAAANsgKesD2GkuaOnkn55MjTvtgRs9"
                    />
                    <span className="recaptchaError">
                      {captchaErrorMessage && 'Please verify recaptcha!'}
                    </span>
                  </div>
                  <button
                    type="submit"
                    name="submit-form"
                    id="recaptcha"
                    value={isSubmitting}
                    disabled={isSubmitting}
                    className="
                      w-full
                      text-white
                      bg-primary
                      rounded
                      border-b border-primary
                      p-3
                      transition
                      hover:bg-opacity-90
                      "
                  >
                    <span className="txt">
                      {isSubmitting
                        ? (
                          <div>
                            <svg role="status" className="inline w-4 h-4 mr-3 text-white animate-spin" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="#E5E7EB" />
                              <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentColor" />
                            </svg>
                            Submitting..
                          </div>
                        )
                        : 'SHARE YOUR FEEDBACK'}
                    </span>
                    <ToastContainer />
                  </button>
                </form>
              </div>
            </div>
            <div className="w-full lg:w-[20%] xl:w-[20%] item-center xl:order-none order-1">
              <div className="xl:mb-12 lg:mb-0">
                <div className="md:mb-[4.5rem] lg:mb-[2.5rem] mb-0">
                  <h2
                    className="
                    mt-2
                    text-white
                    font-normal
                    text-[30px]
                    xl:text-[40px]
                    "
                  >
                    Contact
                    <DashIcon />
                    Us
                  </h2>
                  <p className="text-base text-text mt-2">
                    {data.contentfulContactPage.about}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Faq />
    </section>
  );
};
export default ContactSection;
