/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import ExpandArrowBtn from '../icons/ExpandArrow';

const FaqSection = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulContactPage {
        faqTitle
        frequentlyAskQuestions {
          question
          respond
        }
      }
    }
  `);

  const Item = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <div className="border border-muted2 rounded shadow-md mb-[14px] ">
        <button
          type="button"
          aria-label="Open item"
          title="Faq"
          className="cursor-auto flex items-center justify-between w-full p-4 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <p className="text-lg font-medium text-white text-left">{title}</p>
          <ExpandArrowBtn
            className={`ml-md w-3 text-white transform transition-transform duration-200 ${
            isOpen ? 'rotate-180' : ''
          }`}
          />
        </button>
        {isOpen && (
          <div className="bg-[#4bb7d0b0] pt-0">
            <p className="p-4 text-white font-normal text-16md md:text-2md-1">{children}</p>
          </div>
        )}
      </div>
    );
  };
  const faqListData = data.contentfulContactPage.frequentlyAskQuestions;

  return (
    <section className="lg:pb-[80px] py-0 bg-background">
      <div className="px-[1.125rem] md:px-[6.25rem] mb-[40px] lg:mb-0">
        <div className="items-center mx-auto containet">
          <div className="w-full">
            <div className="space-y-4">
              <div className="bg-background mb-[30px]">
                <h2 className="mb-md relative md:text-3xl text-9md font-bold tracking-tight text-text sm:text-4xl">{data.contentfulContactPage.faqTitle}</h2>
              </div>
              <div className="">
                {faqListData.map(({
                  question, respond,
                }, index) => (
                  <Item title={question} key={index}>
                    {respond}
                  </Item>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
