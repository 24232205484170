/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React from 'react';

import Seo from '../components/Seo';
import Layout from '../components/layout';
import ContactSection from '../components/ContactSection';

const ContactPage = () => (
  <Layout>
    <div className="overflow-hidden bg-background">
      <Seo title="Contact" />
      <ContactSection />
    </div>
  </Layout>
);

export default ContactPage;
